import React, { useEffect, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import productAPI from '../../../../../api/product'
import Loading from '../../../../Order/Tabs/Loading'
import useAPILocal from '../../../../../hooks/useAPILocal'
import Modal from '../../../../Modal'
import TextInput from '../../../../Reusable/Widgets/TextInput'
import useForm from '../../../../../hooks/useForm'
import matchersAPI from '../../../../../api/matchers'
import AutoCompleteInput from '../../../../Reusable/Widgets/AutoCompleteInput'
import { toast } from 'react-toastify'

export default function EditErpTmsModal({ onCloseModal, partnerId, paramId }) {
  const [matchers, setMatchers] = useState()

  const getProductMatchingStrings = useAPILocal(
    matchersAPI.getProductMatchingStrings
  )
  const updateProductMatchingStrings = useAPILocal(
    matchersAPI.updateProductMatchingStrings
  )

  const init = {
    myProduct: '',
    code: '',
  }

  const validationSchema = {
    myProduct: ['required'],
    code: ['required'],
  }

  const { errors, handleOnChange, values, resetForm, handleSubmit, setValues } =
    useForm(init, validationSchema, async result => {
      const strings = result.code.split('\n').filter(item => item.length !== 0)
      updateProductMatchingStrings.request(
        partnerId,
        paramId,
        strings,
        result?.myProduct?.id
      )
        .then(() => {
          resetForm()
          toast.success('Matcher strings updated successfully')
          onCloseModal(strings)
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Something went wrong')
        })
    })

  useEffect(() => {
    setValues({
      code: matchers?.map(item => item.matcher).join('\n'),
      myProduct: matchers?.[0]?.myProduct,
    })
  }, [matchers])

  useEffect(() => {
    if (partnerId)
      getProductMatchingStrings
        .request(partnerId, paramId)
        .then(res => setMatchers(res.data))
  }, [updateProductMatchingStrings.isResolved])

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  return (
    <Modal visible={true} onClose={() => onCloseModal(null)}>
      <Typography color="primary" style={{ fontWeight: '700' }}>
        TMS Number
      </Typography>
      <AutoCompleteInput
        flex="0.5"
        label="My Product"
        value={values.myProduct}
        api={{
          src: productAPI.list,
          params: {},
        }}
        renderItem={option => `${option?.id} ${option?.name}`}
        {...additionalProps('myProduct')}
      />
      <TextInput
        placeholder="Enter one code per line"
        label="Enter matcher codes (one code per line)"
        onChange={e => {
          return handleOnChange({ name: 'code', value: e })
        }}
        error={errors.code}
        value={values.code}
        disabled={false}
        name="code"
        id="code"
        multiline
        multilineHeight="130px"
      />
      <Button
        style={{
          marginTop: '20px',
          marginRight: '15px',
          width: '100%',
        }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={updateProductMatchingStrings.isPending}
      >
        {updateProductMatchingStrings.isPending ? (
          <Loading color="#FFF" />
        ) : (
          'Save'
        )}
      </Button>
    </Modal>
  )
}
