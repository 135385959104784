import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  Switch,
} from '@material-ui/core'
import { toast } from 'react-toastify';
import React, { useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import Container from '../../../../Reusable/Container'
import Fixed from '../../../../Reusable/Fixed'
import PageHeading from '../../../../Reusable/PageHeading'
import PageTitle from '../../../../Reusable/PageTitle'
import Scrollable from '../../../../Reusable/Scrollable'
import { formatContactNo, formatLocation } from '../../../../../helpers/util'
import ROUTES from '../../../../../routes/route_contants'
import withPaginationLocal from '../../../../../HOC/withPaginationLocal'
import TabPanel from '../../../../Reusable/TabPanel'
import connectionsAPI from '../../../../../api/connections'
import productAPI from '../../../../../api/product'
import Products from './Tabs/Products'
import Locations from './Tabs/Locations'
import Contacts from './Tabs/Contacts'
import Tanks from './Tabs/Tanks'
import useAPILocal from '../../../../../hooks/useAPILocal'
import matchersAPI from '../../../../../api/matchers'
import Modal from '../../../../Modal'
import TextInput from '../../../../Reusable/Widgets/TextInput'
import useForm from '../../../../../hooks/useForm'
import Loading from '../../../../Loading'
import EditIcon from '@material-ui/icons/Edit'
import ShareProductModal from './ShareProductModal'

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '17px',
    lineHeight: '20px',
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#3298FF',
    margin: 'auto 0px',
    fontStyle: 'normal',
  },
  content1: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#636363',
  },
  content2: {
    fontSize: '17px',
    lineHeight: '20px',
    fontFamily: 'Lato',
    fontWeight: 500,
    color: '#1A1A1A',
    marginTop: '10px',
    paddingRight: '25px',
  },
  content3: {
    fontSize: '13px',
    lineHeight: '15px',
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#1A1A1A',
    marginTop: '10px',
  },
  content4: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 500,
    color: '#3298FF',
    marginTop: '10px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  gridTable: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  blueBox: {
    backgroundColor: 'rgb(238, 242, 247, 0.4)',
    padding: '20px',
    margin: '10px 0px 30px 0px',
    borderRadius: '7px',
  },
  tableContent: {
    backgroundColor: '#ffffff',
    border: '1px solid #eef2f7',
    padding: '20px',
    margin: '20px 0px',
    borderRadius: '7px',
  },
  tableHeading: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 500,
    color: '#636363',
  },
  tableData: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#1A1A1A',
  },
}))
function PartnerPage() {
  const params = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [matchers, setMatchers] = useState()
  const getMatchingStrings = useAPILocal(matchersAPI.getMatchingStrings)
  const updateMatchingStrings = useAPILocal(matchersAPI.updateMatchingStrings)
  const createMatchingStrings = useAPILocal(matchersAPI.createMatchingStrings)
  const partnerDetails = useAPILocal(connectionsAPI.partnerDetails)
  const [partnerData, setPartnerData] = useState()
  const [iShareFilter, setIShareFilter] = useState(false)
  const [openShareProductModal, setOpenShareProductModal] = useState(false)
  const id = history?.location.state.id

  useEffect(() => {
    if (id) partnerDetails.request(id).then(res => setPartnerData(res?.data))
  }, [])

  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }
  const onClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (partnerData !== undefined)
      getMatchingStrings
        .request(partnerData?.partner?.tenant_id)
        .then(res => setMatchers(res.data))
  }, [partnerData])

  const TankList = useMemo(
    () =>
      withPaginationLocal(connectionsAPI.partnerTanks, {
        partnershipId: params.connection_id,
        tankType: '',
      })(Tanks),
    []
  )

  const ProductList = useMemo(
    () =>
      withPaginationLocal(
        iShareFilter
          ? productAPI.listSharedOwn
          : connectionsAPI.partnerProducts,
        iShareFilter
          ? { search: '', view: '', partnerId: partnerData?.partner?.tenant_id }
          : { partnershipId: params.connection_id }
      )(({ apiResult }) => {
        return (
          <Products
            apiResult={apiResult}
            partnerId={partnerData?.partner?.tenant_id || '-'}
          />
        )
      }),
    [partnerData, iShareFilter]
  )

  const LocationList = useMemo(
    () =>
      withPaginationLocal(connectionsAPI.partnerLocations, {
        partnershipId: params.connection_id,
      })(({ apiResult }) => {
        return (
          <Locations
            apiResult={apiResult}
            partnerId={partnerData?.partner?.tenant_id || '-'}
          />
        )
      }),
    [partnerData]
  )

  const ContactList = useMemo(
    () =>
      withPaginationLocal(connectionsAPI.partnerContacts, {
        partnershipId: params.connection_id,
      })(Contacts),
    []
  )

  const tabs = [
    {
      label: 'Products',
    },
    {
      label: 'Tanks',
    },
    {
      label: 'Locations',
    },
    {
      label: 'Contacts',
    },
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const init = {
    code: '',
  }

  const validationSchema = {
    code: ['required'],
  }

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm(init, validationSchema, async result => {
      const strings = result.code.split('\n').filter(item => item.length !== 0)
      if (strings.length === 0)
        await createMatchingStrings.request(partnerData?.partner?.tenant_id, strings)
          .then(res => {
            toast.success('ERP/TMS numbers created successfully')
            resetForm()
          })
          .catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
          })
      else
        await updateMatchingStrings.request(partnerData?.partner?.tenant_id, strings)
          .then(res => {
            toast.success('ERP/TMS numbers updated successfully')
            resetForm()
          })
          .catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
          })

      setTimeout(() => {
        if (
          createMatchingStrings.isRejected ||
          createMatchingStrings.isResolved ||
          updateMatchingStrings.isRejected ||
          updateMatchingStrings.isResolved
        )
          toggleModal()
        window.location.reload()
      }, 2000)
    })

  useEffect(() => {
    setValues({ code: matchers?.map(item => item.matcher).join('\n') })
  }, [matchers])

  return (
    <>
      {partnerDetails?.isResolved ? (
        <Container pt={3}>
          <Fixed px={3}>
            <PageHeading mb={2}>
              <PageTitle backButton title={'Connected Partner: ' + partnerData?.partner?.tenant_name} />
              {(value === 0 && iShareFilter) ? (
                <Button variant="contained" color="primary" onClick={() => setOpenShareProductModal(true)}>
                  Share Product
                </Button>
              ) : null}
            </PageHeading>
          </Fixed>
          <Scrollable p={3}>
            <Grid container className={classes.blueBox}>
              <Grid item md={3} className={classes.grid}>
                <Typography className={classes.content1}>Name</Typography>
                <Typography className={classes.content2}>
                  {partnerData?.partner?.tenant_name}
                </Typography>
              </Grid>

              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Location</Typography>
                <Typography className={classes.content2}>
                  {partnerData?.partner?.primary_location
                    ? formatLocation(partnerData?.partner?.primary_location)
                    : '-'}
                </Typography>
                <Typography
                  className={classes.content4}
                  onClick={() =>
                    history.push(
                      `${ROUTES.LOCATION_DETAILS}/${partnerData?.partner?.primary_location?.location_id}`
                    )
                  }
                >
                  View Location
                </Typography>
              </Grid>

              <Grid item md={3} className={classes.grid}>
                <Typography className={classes.content1}>Email ID</Typography>
                <Typography className={classes.content2}>
                  {partnerData?.tenant?.tenant_email || '-'}
                </Typography>
              </Grid>
              <Grid item md={2} className={classes.grid}>
                <Typography className={classes.content1}>
                  Contact No.
                </Typography>
                <Typography className={classes.content2}>
                  {formatContactNo(partnerData?.tenant?.tenant_phone) || '-'}
                </Typography>
              </Grid>
              <Grid item md={3} className={classes.grid}>
                <Typography className={classes.content1}>
                  Connected On
                </Typography>
                <Typography className={classes.content2}>
                  {moment(partnerData?.accepted_on).format('DD MMM YYYY') ||
                    '-'}
                </Typography>
              </Grid>
              <Grid item md={3} className={classes.grid}>
                <Typography className={classes.content1}>
                  ERP/TMS Number(s)
                </Typography>
                <Typography className={classes.content2}>
                  {matchers?.map(item => item.matcher)?.join(', ') || '-'}
                  <EditIcon style={{ fontSize: 18, margin: '-2px 12px' }} onClick={toggleModal} />
                </Typography>
              </Grid>
            </Grid>
            <Box my={2} style={{ display: 'flex' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="white"
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    style={
                      index === value
                        ? {
                            color: 'white',
                            backgroundColor: '#3298FF',
                            height: '100%',
                            borderRadius: '30px',
                          }
                        : {}
                    }
                  />
                ))}
              </Tabs>
              {value === 0 ? (
                <div style={{ marginLeft: 'auto'}}>
                  Shared to me
                  <Switch
                    color="primary"
                    checked={iShareFilter}
                    onChange={(e, val) => setIShareFilter(val)}
                    name="shared-switch"
                  />
                  I Share
                </div>
              ) : null}
            </Box>
            <TabPanel value={value} index={0}>
              <ProductList />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TankList />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <LocationList />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ContactList />
            </TabPanel>

            <Modal visible={isOpen} onClose={onClose}>
              <Typography color="primary" style={{ fontWeight: '700' }}>
                TMS Number
              </Typography>
              <TextInput
                placeholder="Enter one code per line"
                label="Enter matcher codes (one code per line)"
                onChange={e => {
                  return handleOnChange({ name: 'code', value: e })
                }}
                error={errors.code}
                value={values.code}
                disabled={false}
                name="code"
                id="code"
                multiline
                multilineHeight="130px"
              />
              <Button
                style={{
                  marginTop: '20px',
                  marginRight: '15px',
                  width: '100%',
                }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={updateMatchingStrings.isPending}
              >
                {updateMatchingStrings.isPending ? (
                  <Loading color="#FFF" />
                ) : (
                  'Save'
                )}
              </Button>
            </Modal>
          </Scrollable>
        </Container>
      ) : (
        <Loading />
      )}
      {openShareProductModal ? (
        <ShareProductModal
          data={null}
          onClose={() => setOpenShareProductModal(false)}
        />
      ) : null}
    </>
  )
}

export default PartnerPage
