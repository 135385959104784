import client from '../../hooks/useClient'

const productAPI = {}

productAPI.create = async data => {
  return client.post(`/products/create`, { ...data })
}

productAPI.bulkCreate = async file => {
  const formData = new FormData()
  formData.append('file', file)
  return client.post(`/products/bulk/create`, formData)
}

productAPI.update = async (productId, params = {}) => {
  return client.patch(`/products/${productId}`, { ...params })
}

productAPI.list = async (params = {}) => {
  return client.get(`/products/list`, { ...params })
}

productAPI.info = async productId => {
  return client.get(`/products/${productId}`)
}

productAPI.deleteProduct = async productId => {
  return client.delete(`/products/${productId}`)
}

productAPI.bulkList = async () => {
  return client.get(`/products/bulk-list`)
}

productAPI.listSharedForeign = async data => {
  return client.get(`/products/shared/foreign?search=${data.search}`)
}

productAPI.listSharedOwn = async data => {
  return client.get(
    `/products/shared/own?search=${data.search}&view=${data.view}&partnerId=${data.partnerId || ''}`
  )
}

productAPI.productTanks = async data => {
  return client.get(`/products/${data.productId}/tanks?search=${data.search}`)
}

export default productAPI
