import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import TableHead from '../../../../../Reusable/TableHead'
import Scrollable from '../../../../../Reusable/Scrollable'
import TableBody from '../../../../../Reusable/TableBody'
import Container from '../../../../../Reusable/Container'
import Loading from '../../../../../Order/Tabs/Loading'
import routes from '../../../../../../routes/route_contants'
import EditIcon from '@material-ui/icons/Edit'
import EditErpTmsModal from '../EditErpTmsModal'

function Products({ apiResult, partnerId }) {
  const [openErpTmsModal, setOpenErpTmsModal] = useState(null)
  const history = useHistory()

  const columns = [
    {
      label: 'Name',
      value: e => <div style={{ textDecorationLine: 'underline' }}>{e?.name}</div>,
      minWidth: 180,
    },
    {
      label: 'UN Number',
      value: e => {
        return e?.unNumber
      },
      maxWidth: 300,
      flex: 2,
    },
    {
      label: 'ERP/TMS Number(s)',
      value: e => (
        <div>
          <EditIcon
            style={{ fontSize: 18, margin: '-2px 10px' }}
            onClick={(el) => {
              el.stopPropagation()
              setOpenErpTmsModal(e)
            }}
          />
          {e?.stringMatchers?.join(', ') || '-'}
        </div>
      ),
    },
  ]

  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data?.results}
            rowOnClick={row =>
              history.push(`${routes.CONNECTION_PRODUCT_DETAILS}/${row.id}`, {
                partnerId,
              })
            }
          />
        )}
      </Scrollable>
      {openErpTmsModal ? (
        <EditErpTmsModal
          partnerId={openErpTmsModal?.tenant?.tenant_id}
          paramId={openErpTmsModal?.id}
          onCloseModal={(newCodes) => {
            if (newCodes) {
              const newData = {
                ...openErpTmsModal,
                stringMatchers: newCodes
              }
              apiResult.updateData(openErpTmsModal.id, newData)
            }
            setOpenErpTmsModal(null)
          }}
        />
      ) : null}
    </Container>
  )
}

Products.propTypes = {
  apiResult: PropTypes.object,
}

Products.defaultProps = {
  apiResult: {},
}

export default Products
