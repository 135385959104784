import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Stack } from '@mui/system'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import TextInput from '../../Reusable/Widgets/TextInput'
import useForm from '../../../hooks/useForm'
import useStateValue from '../../../hooks/useStateValue'
import './edittenant.scss'
import useAPILocal from '../../../hooks/useAPILocal'
import AddLocationModal from '../../Location/AddLocationModal'
import tenantAPI from '../../../api/tenant'
import { formatLocation } from '../../../helpers/util'
import LoadingIndicator from '../../Order/Tabs/Loading'
import LogoPlaceholderTenant from '../../../assets/logoPlaceholderTenant.png'
import Loading from '../../Loading'
import locationAPI from '../../../api/location'
import { toast } from 'react-toastify'

function EditTenantDetails() {
  const [image, setImage] = useState()
  const [isPublic, setIsPublic] = useState()

  const history = useHistory()
  const tenantInfoUpdate = useAPILocal(tenantAPI.tenantInfoUpdate)
  const tenantDetails = useAPILocal(tenantAPI.tenantDetails)

  const uploadTenantLogo = useAPILocal(tenantAPI.uploadTenantLogo)

  const [state] = useStateValue()

  const [isOpenLocation, setIsOpenLocation] = useState(false)
  const [tenantInfo, setTenantInfo] = useState()

  const toggleModalLocation = () => {
    setIsOpenLocation(!isOpenLocation)
  }

  const [newImage, setNewImage] = useState(null)

  const onImageChange = e => {
    const url = URL.createObjectURL(e.target.files[0])
    setNewImage(url)
    setImage(e.target.files[0])
  }
  const validationSchema = {
    tenant_name: ['required'],
    tenant_ein: ['required', 'eq:9', 'number:EIN should be a number'],
  }

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm(
      {
        tenant_name: '',
        tenant_address: '',
        tenant_ein: '',
      },
      validationSchema,
      async result => {
        const updateParams = {
          ...tenantInfo,
          tenant_name: result.tenant_name,
          tenant_address: result.tenant_address,
          tenant_ein: result.tenant_ein,
          primary_location: tenantInfo?.primary_location?.location_id,
          is_public: isPublic,
        }

        try {
          await tenantInfoUpdate.request(result.tenant_id, updateParams)
          if (image) {
            const formData = new FormData()
            formData.append('image', image)
            await uploadTenantLogo.request({
              tenantId: result?.tenant_id,
              image: formData,
            })
          }
          toast.success('Changes saved successfully')
          history.goBack()
        } catch (error) {
          toast.error(error?.clientMessage || error?.message || 'Error')
        }
      }
    )

  const onLocationAdded = (locationData, type) => {
    setTenantInfo({
      ...tenantInfo,
      primary_location: locationData,
      tenant_ein: values.tenant_ein,
      tenant_name: values.tenant_name,
    })
    setIsOpenLocation(false)
  }

  useEffect(() => {
    const obj = {
      tenant_name: tenantInfo?.tenant_name,
      tenant_address: tenantInfo?.tenant_address,
      tenant_id: tenantInfo?.tenant_id,
      tenant_ein: tenantInfo?.tenant_ein || '',
    }
    setValues(obj)
  }, [tenantInfo])

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  useEffect(() => {
    tenantDetails
      .request({ tenantId: state?.user?.user_info?.tenant?.tenant_id })
      .then(res => {
        setTenantInfo(res.data)
        setIsPublic(res?.data?.is_public)
      })
  }, [])

  const handleChange = event => {
    setIsPublic(prev => !prev)
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Edit Details" backButton />
        </PageHeading>
      </Fixed>
      {tenantInfoUpdate.isPending || !tenantInfo ? (
        <LoadingIndicator m={4} />
      ) : (
        <Scrollable mt={2} style={{ height: '100%' }}>
          <Box display="flex" height="100%">
            <Box overflow="auto" height="100%">
              <Box display="flex" padding="30px">
                <Box mr={2}>
                  <span
                    style={{
                      color: 'rgb(50, 152, 255)',
                      fontWeight: 600,
                      cursor: 'pointer',
                    }}
                  >
                    Edit Tenant Details
                  </span>
                </Box>
              </Box>
            </Box>
            <Box mx={4}>
              <Divider orientation="vertical" />
            </Box>
            <Box
              display="flex"
              flex={1}
              flexWrap="wrap"
              overflow="auto"
              mr={2}
              px={1}
              pb={2}
              height="100%"
              alignContent="flex-start"
            >
              {tenantDetails.isPending ? (
                <Loading />
              ) : (
                <Box display="flex">
                  <Box height="100%">
                    <Box flex="1" px={1} pt={2} overflow="auto" height="100%">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        gap="50px"
                      >
                        <Stack direction="column" gap="20px">
                          <TextInput
                            flex="0.4"
                            label="Company Name"
                            name="tenant_name"
                            {...additionalProps('tenant_name')}
                          />
                          <TextInput
                            flex="0.4"
                            label="EIN"
                            name="tenant_ein"
                            {...additionalProps('tenant_ein')}
                          />
                          <Box>
                            {tenantInfo?.primary_location !== null && (
                              <Typography
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {formatLocation(
                                  tenantInfo?.primary_location,
                                  'postal_address city state country postal_code'
                                )}
                              </Typography>
                            )}
                          </Box>
                          {isPublic !== undefined && (
                            <FormGroup style={{ alignItems: 'flex-start' }}>
                              <FormControlLabel
                                style={{ marginLeft: '0px' }}
                                control={
                                  <Switch
                                    color="primary"
                                    checked={isPublic}
                                    onChange={handleChange}
                                  />
                                }
                                label="Is Public"
                                labelPlacement="top"
                              />
                            </FormGroup>
                          )}
                        </Stack>
                        <Stack direction="column" gap="20px">
                          <Box pt={6}>
                            <div>
                              <input
                                type="file"
                                accept="image/*"
                                placeholder="Add profile picture"
                                hidden
                                id="f03"
                                style={{
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                }}
                                onChange={onImageChange}
                              />
                              <label htmlFor="f03" style={{ cursor: 'pointer' }}>
                                <div className="edittenant">
                                  <img
                                    className="edittenantimage"
                                    src={
                                      newImage ||
                                      `data:image/png;base64,${tenantInfo?.tenant_logo}`
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null
                                      currentTarget.src = LogoPlaceholderTenant
                                    }}
                                    alt=""
                                    style={{
                                      opacity: newImage !== undefined ? 1 : 0.3,
                                    }}
                                  />
                                  {newImage === undefined && (
                                    <div className="browselogo">Browse Logo</div>
                                  )}
                                </div>
                              </label>
                            </div>
                          </Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => toggleModalLocation()}
                            style={{ width: 'fit-content' }}
                          >
                            {tenantInfo?.primary_location !== null
                              ? 'Edit'
                              : 'Add'}{' '}
                            Location
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Scrollable>
      )}
      <Fixed>
        <Box py={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={tenantInfoUpdate.isPending || !tenantInfo}
          >
            Save Changes
          </Button>
        </Box>
      </Fixed>
      {isOpenLocation && (
        <AddLocationModal
          isOpen={isOpenLocation}
          toggleModal={toggleModalLocation}
          onDone={onLocationAdded}
          location={tenantInfo?.primary_location}
        />
      )}
    </Container>
  )
}

export default EditTenantDetails
