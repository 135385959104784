import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react'
import { Box, Button } from '@material-ui/core'
import { toast } from 'react-toastify';
import Geocode from 'react-geocode'
import Loading from '../Loading'
import Modal from '../Modal'
import useCurrentLocation from '../../hooks/useCurrentLocation'
import countriesAPI from '../../api/countries'
import useAPILocal from '../../hooks/useAPILocal'
import locationAPI from '../../api/location'
import tenantAPI from '../../api/tenant'
import contactsAPI from '../../api/contacts'
import SelectInput from '../Reusable/Widgets/SelectInput'
import TextInput from '../Reusable/Widgets/TextInput'
import useForm from '../../hooks/useForm'

function AddLocationModal(props) {
  const { isOpen, toggleModal, onDone, location, superAdmin, tenantId } = props

  const coords = useCurrentLocation()

  const [detailsVisible, setDetailsVisible] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [opContacts, getOpContacts] = useState([])

  const toggleDetailsVisible = () => setDetailsVisible(prev => !prev)
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)

  const getCountries = useAPILocal(countriesAPI.getCountries)
  const getStatesByCountry = useAPILocal(countriesAPI.getStatesByCountry)
  const createNewLocation = useAPILocal(locationAPI.createNewLocation)
  const updateLocation = useAPILocal(locationAPI.updateLocation)
  const tenantLocation = useAPILocal(tenantAPI.tenantLocation)
  const getContacts = useAPILocal(contactsAPI.getContacts)

  const init = {
    facility_number: '',
    postal_address: '',
    postal_code: '',
    phone_no: '',
    latitude: '',
    longitude: '',
    location_type: 'tenant_location',
    city: '',
  }

  const validationSchema = {
    facility_number: ['required:Facility number is required', 'number'],
    phone_no: ['required', 'eq:12', 'dashedNumber'],
    postal_address: ['required:Address is required'],
    city: ['required:City is required'],
    postal_code: ['required:Postal Code is required', 'number'],
  }

  const dashedNumber = (value) => {
    let newValue = ''
    value = value || ''
    value = value?.split('-')?.join('')
    value = value?.replace(/\D[^\.]/g, '')
    newValue = value?.slice(0, 3)
    if (value?.slice(0, 3) !== '' && value?.slice(3, 6) !== '') newValue += '-'
    newValue += value?.slice(3, 6)
    if (value?.slice(3, 6) !== '' && value?.slice(6) !== '') newValue += '-'
    newValue += value?.slice(6)
    return newValue
  }

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm(
      location
        ? {
          country: location?.country?.id,
          state: location?.state?.id,
          city: location?.city,
          postal_address: location?.postal_address,
          postal_code: location?.postal_code,
          facility_number: location?.facility_number,
          phone_no: dashedNumber(location?.phone_no),
          latitude: location?.latitude,
          longitude: location?.longitude,
          location_type: location?.location_type || 'tenant_location',
          primaryContact: location?.primaryContact?.id || '',
        } : init,
      validationSchema,
      async submitValues => {
        const val = { ...submitValues}
        if (val.facility_number === '') delete val['facility_number']

        const newLatitude = !val.latitude || Number.isNaN(Number(val.latitude)) ? '' : Number(val.latitude)
        const newLongitude = !val.longitude || Number.isNaN(Number(val.longitude)) ? '' : Number(val.longitude)
        const hasCoordinatesModified = newLatitude !== '' && newLongitude !== '' && (
          (newLatitude ? location?.latitude !== Number(newLatitude) : false) ||
          (newLongitude ? location?.longitude !== Number(newLongitude) : false)
        )
        if (hasCoordinatesModified) {
          val.latitude = Number(val.latitude)
          val.longitude = Number(val.longitude)
        }

        if (superAdmin) {
          tenantLocation
            .request(tenantId, val)
            .then(res => {
              toast.success('Location updated successfully')
              onDone(res?.data)
            })
            .catch(error => {
              toast.error(error?.clientMessage || error?.message || 'Error')
            })
          return
        }

        if (location && !superAdmin) {
          updateLocation
            .request(location?.location_id, val)
            .then(res => {
              toast.success('Location updated successfully')
              onDone(res?.data, 'update')
              toggleModal(false)
            })
            .catch(error => {
              toast.error(error?.clientMessage || error?.message || 'Error')
            })
        } else {
          createNewLocation
            .request(val)
            .then(res => {
              toast.success('Location created successfully')
              onDone(res?.data, 'create')
              resetForm({ val: '' })
              toggleModal(false)
            })
            .catch(error => {
              toast.error(error?.clientMessage || error?.message || 'Error')
            })
        }
    })

  useEffect(() => {
    if (location)
      setValues({
        country: location?.country?.id,
        state: location?.state?.id,
        city: location?.city,
        postal_address: location?.postal_address,
        postal_code: location?.postal_code,
        facility_number: location?.facility_number,
        phone_no: dashedNumber(location?.phone_no),
        latitude: location?.latitude,
        longitude: location?.longitude,
        location_type: location?.location_type || 'tenant_location',
        primaryContact: location?.primaryContact?.id || '',
      })
  }, [location])

  useEffect(() => {
    setValues({
      ...values,
      latitude: location?.latitude || coords?.latitude,
      longitude: location?.longitude || coords?.longitude,
    })
  }, [coords])

  const parseAddressComponents = addressComponents => {
    let postal_address = ''
    let postal_code = ''
    let city = ''
    let state = ''
    let country = ''

    addressComponents.forEach(component => {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          postal_address = `${component.long_name} ${postal_address}`
          break
        }
        case 'route': {
          postal_address += component.long_name
          break
        }
        case 'locality':
          city = component.long_name
          break
        case 'administrative_area_level_1': {
          const googleState = component.long_name
          const foundState = states.find(item => item.label === googleState)
          state = foundState ? foundState.value : ''
          break
        }
        case 'country': {
          const googleCountry = component.long_name
          const foundCountry = countries.find(
            item => item.label === googleCountry
          )
          country = foundCountry ? foundCountry.value : ''
          break
        }
        case 'postal_code':
          postal_code = component.long_name
          break
      }
    })

    return { postal_address, postal_code, city, state, country }
  }

  const setFormInputs = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      response => {
        if (!response.results[0]) {
          console.error('No results from Geocode API')
          return
        }
        const addressComponents = response.results[0].address_components
        const address = parseAddressComponents(addressComponents)

        setValues(prevValues => ({
          ...prevValues,
          latitude: lat,
          longitude: lng,
          ...address,
        }))
      },
      error => {
        console.error(error)
      }
    )
  }

  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    setValues({ ...values, latitude: latLng.lat(), longitude: latLng.lng() })
    setFormInputs(latLng.lat(), latLng.lng())
  }

  const getLocationFromAddress = () => {
    const state = states.find(item => item.value === values.state)?.label;
    const country = countries.find(item => item.value === values.country)?.label;
    const address = `${values.postal_address || ''} ${values.city || ''} ${values.postal_code || ''} ${state || ''} ${country || ''}`.trim();
    Geocode.fromAddress(address).then(res => {
      const { lat, lng } = res.results[0].geometry.location
      setValues({
        ...values,
        latitude: lat,
        longitude: lng,
      })
    })
  }

  useEffect(() => {
    getCountries.request().then(res => {
      const newArray = res?.data?.map(response => {
        return {
          label: response?.name,
          value: response?.id,
        }
      })
      setCountries(() => newArray)
    })

    getContacts.request({ search: '' }).then(res => {
      const newArray = res?.data?.results?.map(response => {
        return {
          label: `${response?.name} ${response?.surname}`,
          value: response?.id,
        }
      })
      getOpContacts(newArray)
    })
  }, [])

  useEffect(() => {
    if (values.country)
      getStatesByCountry.request(values.country).then(res => {
        const newArray = res?.data?.map(response => {
          return {
            label: response?.name,
            value: response?.id,
          }
        })
        setStates(() => newArray)
        getLocationFromAddress()
      })
  }, [values.country])

  return (
    <Modal
      visible={isOpen}
      onClose={toggleModal}
      style={{ width: '800px', maxWidth: '800px' }}
      locationModal
    >
      {(!createNewLocation.isRejected && !createNewLocation.isResolved) ||
      (!updateLocation.isRejected && !updateLocation.isResolved) ? (
        <>
          <Box
            display="flex"
            className="form-row"
            justifyContent="space-between"
          >
            <TextInput
              flex={1}
              label="Facility ERP/TMS Number"
              onChange={e => {
                return handleOnChange({ name: 'facility_number', value: e })
              }}
              error={errors.facility_number}
              value={values.facility_number}
              name="facility_number"
            />
            <SelectInput
              flex={1}
              label="Link Operational Contact"
              name="primaryContact"
              options={opContacts}
              type="contact"
              onChange={e => {
                return handleOnChange({ name: 'primaryContact', value: e })
              }}
              value={values.primaryContact || ''}
            />
            <TextInput
              flex={1}
              label="Contact Number"
              onChange={e => {
                return handleOnChange({
                  name: 'phone_no',
                  value: e,
                })
              }}
              error={errors.phone_no}
              value={values.phone_no}
              name="phone_no"
            />
          </Box>
          <Box
            display="flex"
            className="form-row"
            justifyContent="space-between"
          >
            <TextInput
              label="Address"
              flex={1}
              onChange={e => {
                return handleOnChange({ name: 'postal_address', value: e })
              }}
              error={errors.postal_address}
              value={values.postal_address}
              name="postal_address"
              onBlur={getLocationFromAddress}
            />
            <TextInput
              label="City"
              onChange={e => {
                return handleOnChange({ name: 'city', value: e })
              }}
              error={errors.city}
              value={values.city}
              name="city"
              flex={1}
              onBlur={getLocationFromAddress}
            />
            <TextInput
              label="Zip Code/Postal Code"
              flex={1}
              onChange={e => {
                return handleOnChange({ name: 'postal_code', value: e })
              }}
              error={errors.postal_code}
              value={values.postal_code}
              name="postal_code"
              onBlur={getLocationFromAddress}
            />
          </Box>

          <Box
            display="flex"
            className="form-row"
            justifyContent="space-between"
          >
            <SelectInput
              flex={1}
              label="State/Province"
              name="state"
              options={states}
              onChange={e => {
                return handleOnChange({ name: 'state', value: e })
              }}
              value={values?.state || ''}
              onBlur={getLocationFromAddress}
            />
            <SelectInput
              flex={1}
              label="Country"
              name="country"
              options={countries}
              onChange={e => {
                return handleOnChange({ name: 'country', value: e })
              }}
              value={values?.country || ''}
              onBlur={getLocationFromAddress}
            />
            <Box flex={1}></Box>
          </Box>
          <Box
            display="flex"
            className="form-row"
            justifyContent="space-between"
          >
            <TextInput
              flex={1}
              label="Latitude"
              disabled
              onChange={e => {
                return handleOnChange({
                  name: 'latitude',
                  value: e,
                })
              }}
              error={errors.latitude}
              value={values.latitude}
              name="latitude"
            />
            <TextInput
              flex={1}
              label="Longitude"
              disabled
              onChange={e => {
                return handleOnChange({
                  name: 'longitude',
                  value: e,
                })
              }}
              error={errors.longitude}
              value={values.longitude}
              name="longitude"
            />
            <Box flex={1}></Box>
          </Box>

          <Box>
            <Map
              containerStyle={{
                height: '35vh',
                position: 'relative',
                margin: '7px',
                marginTop: '20px',
                width: '98%',
              }}
              zoom={15}
              initialCenter={{
                lat: values?.latitude,
                lng: values?.longitude,
              }}
              center={{
                lat: values?.latitude,
                lng: values?.longitude,
              }}
              google={window.google}
              streetView
            >
              <Marker
                onClick={toggleDetailsVisible}
                draggable
                onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
                position={{
                  lat: values?.latitude,
                  lng: values?.longitude,
                }}
                icon={{
                  url: 'https://i.pinimg.com/originals/25/62/aa/2562aacd1a4c2af60cce9629b1e05cf2.png',
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              />

              <InfoWindow
                visible={detailsVisible}
                position={{
                  lat: parseInt(values?.latitude, 10),
                  lng: parseInt(values?.longitude, 10),
                }}
                onClose={toggleDetailsVisible}
              >
                <div style={{ width: '100px', height: '30px' }}>
                  <h1></h1>
                </div>
              </InfoWindow>
            </Map>
          </Box>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={createNewLocation.isPending || updateLocation.isPending}
            style={{ marginTop: '20px' }}
          >
            {createNewLocation.isPending || updateLocation.isPending ? (
              <Loading />
            ) : (
              'Save Location'
            )}
          </Button>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  )
}

AddLocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDone: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
  location: PropTypes.object,
  superAdmin: PropTypes.bool,
  tenantId: PropTypes.string,
}

AddLocationModal.defaultProps = {
  onDone: () => {},
  location: '',
  superAdmin: '',
  tenantId: '',
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  Loading,
})(AddLocationModal)
