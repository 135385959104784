import React, { useState, useEffect } from 'react'
import {
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import Modal from '../../../../Modal'
import TenantModal from "../../MyCompany/Seller/TenantModal"
import useAPILocal from '../../../../../hooks/useAPILocal'
import connectionsAPI from '../../../../../api/connections'
import productAPI from '../../../../../api/product'
import LoadingIndicator from '../../../../Order/Tabs/Loading'
import AutoCompleteInput from '../../../../Reusable/Widgets/AutoCompleteInput'
import CheckBoxInput from '../../../../Reusable/Widgets/CheckBoxInput'
import { toast } from 'react-toastify'

const StyledFormControlLabel = styled(FormControlLabel)({
  padding: '0 5px',
  alignItems: 'start',
})

const StyledLabelDiv = styled('div')({
  margin: '8px 0',
  fontWeight: '400',
  fontFamily: 'Lato, sans-serif',
  fontSize: '15px',
})

const StyledLabelNote = styled('div')({
  lineHeight: '1.5',
  fontSize: '13px',
})

function ShareProductModal({ onClose, data }) {
  const [isOpenTenant, setIsOpenTenant] = useState(false)
  const sharedResources = useAPILocal(connectionsAPI.sharedResources)
  const productTanks = useAPILocal(productAPI.productTanks)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [values, setValues] = useState({
    productToShare: '',
    visibility: '',
    sharedWith: [],
    applyToAllTanks: false
  })

  useEffect(() => {
    setValues({
      ...values,
      visibility: data?.visibility,
    })
  }, [data?.visibility])

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    sharedResources.request({
      entityIds: [values.productToShare?.id],
      visibility: values.visibility,
      entityType: 'PRODUCT',
      partnerIds: values.visibility === 'SELECTED_PARTNERS'
        ? values.sharedWith.map(el => el.id)
        : [],
    }).then(() => {
      if (values.applyToAllTanks) {
        productTanks.request({
          productId: values.productToShare?.id,
          search: '',
        }).then(res => {
          const tankIds = res?.data?.results?.map(el => el.tankRegistrationNo)
          sharedResources.request({
            entityIds: tankIds,
            visibility: values.visibility,
            entityType: 'TANK',
            partnerIds: values.visibility === 'SELECTED_PARTNERS'
              ? values.sharedWith.map(el => el.id)
              : [],
          }).then(() => {
            toast.success('Visibility updated successfully')
            onClose()
            setIsSubmitting(false)
          }).catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
            setIsSubmitting(false)
          })
        }).catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
          setIsSubmitting(false)
        })
      } else {
        toast.success('Visibility updated successfully')
        onClose()
        setIsSubmitting(false)
      }
    }).catch(error => {
      toast.error(error?.clientMessage || error?.message || 'Error')
      setIsSubmitting(false)
    })
  }

  return (
    <>
      <Modal
        visible={true}
        onClose={onClose}
        style={{ padding: '0px 0px 20px 20px' }}
      >
        <Typography
          color="primary"
          style={{ fontWeight: '600', fontSize: '18px', marginBottom: '20px' }}
        >
          Share Product
        </Typography>
        <AutoCompleteInput
          flex="0.5"
          label="Product to share"
          placeholder="Type to search product..."
          value={values.productToShare}
          api={{
            src: productAPI.list,
            params: {},
          }}
          renderItem={option => `${option?.id} ${option?.name}`}
          onChange={(newVal) => setValues({
            ...values,
            productToShare: newVal,
          })}
        />
        <h4 style={{ marginTop: '20px' }}>Product Visibility</h4>
        <RadioGroup
          aria-label="gender"
          name="visibility"
          value={values.visibility}
          onChange={handleChange}
        >
          <StyledFormControlLabel
            value="PRIVATE"
            control={<Radio />}
            label={
              <StyledLabelDiv>
                <div>Private</div>
                <StyledLabelNote>Other cannot see this</StyledLabelNote>
              </StyledLabelDiv>
            }
          />
          <StyledFormControlLabel
            value="PUBLIC"
            control={<Radio />}
            label={
              <StyledLabelDiv>
                <div>Public</div>
                <StyledLabelNote>Visible to everyone, including tenants</StyledLabelNote>
                <StyledLabelNote>that are not connected yet</StyledLabelNote>
              </StyledLabelDiv>
            }
          />
          <StyledFormControlLabel
            value="ALL_PARTNERS"
            control={<Radio />}
            label={
              <StyledLabelDiv>
                <div>All Partners</div>
                <StyledLabelNote>Visible to all connected partners</StyledLabelNote>
              </StyledLabelDiv>
            }
          />
          <StyledFormControlLabel
            value="SELECTED_PARTNERS"
            control={<Radio />}
            label={
              <StyledLabelDiv>
                <div>Selected Partners</div>
                <StyledLabelNote>Only visible to individually selected partners</StyledLabelNote>
              </StyledLabelDiv>
            }
          />
        </RadioGroup>
        {values.visibility === 'SELECTED_PARTNERS' && (
          <>
            <ol style={{ marginLeft: '30px' }}>
              {values?.sharedWith?.map(item => (
                <li>{item.tenantName} </li>
              ))}
            </ol>
            <h4
              style={{
                color: '#003366',
                textDecorationLine: 'underline',
                cursor: 'pointer',
                marginTop: '10px',
              }}
              onClick={() => setIsOpenTenant(true)}
            >
              + Add Tenants
            </h4>
          </>
        )}
        <h4 style={{ marginTop: '20px' }}>Tanks Visibility</h4>
        <div style={{ display: 'flex' }}>
          <Checkbox
            value={values.applyToAllTanks}
            onChange={(e, val) => setValues({
              ...values,
              applyToAllTanks: val,
            })}
          />
          <StyledLabelDiv>
            <div style={{ marginTop: '2px' }}>Apply to all containing tanks</div>
            <StyledLabelNote>
              If checked, same visibility rules will be applied also to all tanks containing product
            </StyledLabelNote>
          </StyledLabelDiv>
        </div>
        {isSubmitting && <LoadingIndicator />}
        {!isSubmitting && (
          <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1.75px 0' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                sharedResources.isPending ||
                productTanks.isPending ||
                !values.productToShare ||
                !values.visibility ||
                (values.visibility === 'SELECTED_PARTNERS' && !values?.sharedWith?.length)
              }
              onClick={handleSubmit}
            >
              Done
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </div>
        )}
        <TenantModal
          isOpen={isOpenTenant}
          toggleModal={() => setIsOpenTenant(false)}
          entityId={data?.id}
          entityType={'PRODUCT'}
          returnIdsOnly={true}
          onSuccess={(partners) => {
            setValues({
              ...values,
              sharedWith: partners,
            })
          }}
        />
      </Modal>
    </>
  )
}

export default ShareProductModal